import { default as ai_45form_45builderne8muadI23Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editMyAR3CgVZCMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index6YiFiAIPUiMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexIpbBnm5A7HMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexSbywTcgArDMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareDSsj3g082TMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsxqHwBJ0lMrMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsyo7ndeJjqiMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showBXmMC0BbTiMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestex0y1yfE9nMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexe3ntJVD75cMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeDXLOfdHnNVMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexi1e84p09pHMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/index.vue?macro=true";
import { default as login7kZp4tK8WVMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackQUK8VwudOvMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailgs0IP8LB3VMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93g3MwrdLPOZMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingBvAJ7Xntk3Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyn8uh1DRrf3Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registero2rRRlDz0YMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensrACMNf0LOHMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accounttoEUmZLnzPMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminto3jsouy6mMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingE7540ULYVJMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsapKRB2DCk6Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexIlHzgD9H1NMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordRwaLbfQqBHMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileqAwyWBOB5fMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceMjYmILkRCEMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsw1f4wsdda2Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as error8XkkylzFhQMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successW1KhOAlIxHMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93bPbdCfnLVWMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as index71gJ8k78dTMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93iFyQXdjFEqMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesYFCPgVIkPwMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93n2ftsBqz42Meta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsYi5mhjixmGMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentials9hWb2fFyUxMeta } from "/codebuild/output/src4205724784/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderne8muadI23Meta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editMyAR3CgVZCMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showBXmMC0BbTiMeta?.name,
    path: "/forms/:slug()/show",
    meta: showBXmMC0BbTiMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexIpbBnm5A7HMeta?.redirect,
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexSbywTcgArDMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareDSsj3g082TMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsxqHwBJ0lMrMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsyo7ndeJjqiMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestex0y1yfE9nMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexe3ntJVD75cMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeDXLOfdHnNVMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login7kZp4tK8WVMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailgs0IP8LB3VMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93g3MwrdLPOZMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingBvAJ7Xntk3Meta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyn8uh1DRrf3Meta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registero2rRRlDz0YMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsw1f4wsdda2Meta?.name,
    path: "/settings",
    meta: settingsw1f4wsdda2Meta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accounttoEUmZLnzPMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminto3jsouy6mMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingE7540ULYVJMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsapKRB2DCk6Meta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexIlHzgD9H1NMeta?.redirect,
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordRwaLbfQqBHMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileqAwyWBOB5fMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceMjYmILkRCEMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error8XkkylzFhQMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successW1KhOAlIxHMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesYFCPgVIkPwMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsYi5mhjixmGMeta || {},
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src4205724784/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]